import CrudForm from '@elentari/components-eve/components/CrudForm';
import Section from '@elentari/components-eve/components/Section';
import DatePicker from '@elentari/components-eve/final-form/DatePicker';
import MaskedField from '@elentari/components-eve/final-form/MaskedField';
import Select from '@elentari/components-eve/final-form/Select';
import TextField from '@elentari/components-eve/final-form/TextField';
import { Box, Button, makeStyles } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import * as R from 'ramda';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Cellphone, CreatedAt, Email, Name } from '../../Components';
import Address, {
  validator as addressValidator,
} from '../../Components/Address';
import AsyncAutoComplete from '../../Components/AsyncAutoComplete';
import ConfirmDialog from '../../Components/ConfirmDialog';
import RFFImageBox from '../../Components/FormFields/ImageBox';
import MessageSnackbar from '../../Components/MessageSnackbar';
import SwitchField from '../../Components/SwitchField';
import When from '../../Components/When';
import { useResourceAcessAudit } from '../../hooks/useResourceAcessAudit';
import yupValidation from '../../lib/yupValidation';
import Details from '../../modules/properties/formGroups/Details';
import {
  CNPJMask,
  CPFMask,
  onlyNumbers,
  phoneUnmask,
  validateDate,
  yupValidateCNPJ,
  yupValidateCPF,
  yupValidateCPFCNPJ,
  yupValidateCellphone,
} from '../../utils/utils';
import TransferirEmMassaDialog from '../customers/acoes/TransferirEmMassaDialog';
import { useFeatureValidation } from '../features/features';
import useIntegration from '../integrations/useIntegration';
import { officesRepository } from '../offices/officesRepository';
import { teamsRepository } from '../teams/teamsRepository';
import { useAbility } from './AbilityContext';
import Chat2DeskUserField from './Chat2DeskUserField';
import { preferencesTemplateRepository } from './PreferencesTemplateRepository';
import confirmacaoUsuarioRepository from './confirmacaoUserRepository';
import { AuthContext } from 'elentari';

export const useStyles = makeStyles(() => ({
  linkReenviarEmail: {
    textAlign: 'end',
  },
  imageContainer: {
    '& img': {
      borderRadius: 10,
    },
  },
  resetPassword: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  label: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 'calc(100% - 60px)',
    '&.Mui-focused': {
      width: '100%',
    },
  },
}));

const UserForm = ({ entityManager, roles }) => {
  const classes = useStyles();
  const history = useHistory();
  const context = useContext(AuthContext);

  const [template, setTemplate] = useState([]);
  const [showOperatingSegment, setShowOperatingSegment] = useState(false);
  const [officeId, setOfficeId] = useState(null);
  const [offices, setOffices] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    state: {},
  });

  const [confirmDialogProps, setConfirmDialogProps] = useState({ open: false });
  const [openTransferirClientes, setOpenTransferirClientes] = useState({
    open: false,
    data: null,
  });

  const [submitting, setSubmitting] = useState(false);
  const [state, dispatch] = useResourceAcessAudit();
  const isFiancaDigitalEnabled = useFeatureValidation('Análise de crédito');

  const getTemplate = async () => {
    const response = await preferencesTemplateRepository.list();
    if (response.ok) {
      setTemplate(response.data);
    }
  };

  const cannotShowOperatingSegment = useCallback(
    (roleId) => {
      const role = R.find(R.propEq('value', +roleId), roles);
      if (
        !R.isNil(role) &&
        [
          'DIRETOR',
          'GERENTE',
          'GERENTE_PRE_ATENDIMENTO',
          'CORRETOR',
          'CORRETOR_1',
        ].includes(role.key.toUpperCase())
      ) {
        setShowOperatingSegment(true);
        return true;
      }
      setShowOperatingSegment(false);
      return false;
    },
    [roles],
  );

  const isCorretor = useCallback(
    (roleId) => {
      const role = R.find(R.propEq('value', +roleId), roles);
      if (
        !R.isNil(role) &&
        ['CORRETOR', 'CORRETOR_1'].includes(role.key.toUpperCase())
      ) {
        return true;
      }
      return false;
    },
    [roles],
  );

  useEffect(() => {
    cannotShowOperatingSegment(entityManager.item.roleId);
  }, [entityManager.item.roleId, cannotShowOperatingSegment]);

  useEffect(() => {
    if (entityManager.item && !R.isEmpty(entityManager.item))
      dispatch({
        type: 'dadosUsuario',
        payload: {
          id: entityManager.item.id,
          nome: entityManager.item.name,
          telefone: entityManager.item?.cellphone
            ? entityManager.item.cellphone
            : null,
        },
      });
  }, [entityManager.item]);

  useEffect(() => {
    const outerSetOffices = async () => {
      const offices = await fetchOffices('');
      setOffices(offices);
    };
    outerSetOffices();
    getTemplate();
  }, []);

  const userSchema = yup.object().shape({
    name: yup.string().trim().required('Obrigatório'),
    username: yup
      .string()
      .trim()
      .max(60)
      .email('E-mail Inválido')
      .required('Obrigatório'),
    cellphone: yup
      .string()
      .trim()
      .required('Obrigatório')
      .nullable()
      .test('test-cellphone', 'Celular Inválido', yupValidateCellphone),
    roleId: yup.string().required('Obrigatório').nullable(true),
    cpf: yup
      .string()
      .test('test-cpf', 'CPF Inválido', yupValidateCPF)
      .nullable(true),
    cnpj: yup
      .string()
      .test('test-cnpj', 'CNPJ Inválido', yupValidateCNPJ)
      .nullable(true),
    operatingSegment: yup
      .string()
      .trim()
      .when('roleId', {
        is: (roleId) => cannotShowOperatingSegment(roleId),
        then: yup.string().nullable().required('Obrigatório'),
      })
      .nullable(),
    endereco: addressValidator(),
    entryDate: yup.date().required('Obrigatório').nullable(),
    creciExpirationDate: yup
      .date()
      .nullable()
      .when('creci', (creci, schema) =>
        creci ? schema.required('Obrigatório') : schema,
      ),
    team: yup
      .object()
      .when('roleId', {
        is: (roleId) => isCorretor(roleId),
        then: yup.object().nullable().required('Obrigatório'),
      })
      .nullable(),
    cpfCnpjComissaoFianca: yup
      .string()
      .test('test-cpf-cnpj', 'CPF/CNPJ Inválido', yupValidateCPFCNPJ)
      .nullable()
      .transform((v) => (v == null ? '' : v)),
  });

  const validate = yupValidation(userSchema);
  const ability = useAbility();

  const chat2DeskIntegration = useIntegration('CHAT2DESK');

  const cannotUpdate =
    ability.cannot('update', {
      ...entityManager.item,
      __type: 'rusers',
    }) && !entityManager.isNew;

  const validatePasswordField = () => {
    if (entityManager.isNew) return false;
    if (entityManager.item.enabled === false) return false;
    return !cannotUpdate;
  };

  const cannotUpdateField = (field) =>
    ability.cannot('update', 'rusers', field) && !entityManager.isNew;

  const handleSubmit = async (data) => {
    const parsed = {
      ...data,
      cellphone: data.cellphone ? phoneUnmask(data.cellphone) : null,
      cpf: data.cpf ? onlyNumbers(data.cpf) : null,
      cnpj: data.cnpj ? onlyNumbers(data.cnpj) : null,
      teamId: data.team ? data.team.id : null,
      officeId: data.office ? data.office.id : null,
      active: !!data.active,
      username: data.username ? data.username.trim().toLowerCase() : null,
      preferencias: JSON.stringify(data.preferencias),
      endereco: JSON.stringify(data.endereco),
    };

    if (typeof data.thumbnail === 'object') {
      parsed.file = data.thumbnail[0];
    }

    if (entityManager.item.active && !parsed.active) {
      setConfirmDialogProps({
        open: true,
        onDeny: () => setConfirmDialogProps({ open: false }),
        onAccept: () => {
          setConfirmDialogProps({ open: false });
          setOpenTransferirClientes({ open: true, data: parsed });
        },
        acceptLabel: 'Transferir',
        denyLabel: 'Cancelar',
        extraActionLabel: 'Ignorar',
        extraAction: async () => {
          setSubmitting(true);
          parsed.dataInativacao = new Date();
          await entityManager.onSubmit(parsed);
          setConfirmDialogProps({ open: false });
          setSubmitting(false);
        },
        title: 'Deseja transferir os clientes desse usuário?',
        content:
          'Caso ignore a transferência, todos os clientes deste usuário serão atualizados para o status "Sem Atendimento". Esse usuário será exibido nos filtros por 15 dias após o bloqueio',
      });

      return;
    }

    if (!entityManager.item.active && parsed.active) {
      parsed.dataInativacao = null;
    }

    const response = await entityManager.onSubmit(parsed);
    const contextUser = context.user;

    if (contextUser.id === response.data.id) {
      context.updateUser({
        name: response.data.name,
        email: response.data.username,
        thumbnail: response.data.photoUrl,
        openWhatsappLinksOnApi:
          response.data.preferencias.openWhatsappLinksOnApi,
      });

      window.dispatchEvent(new Event('storage'));
    }
  };

  const handleSubmitTransferencia = async (values) => {
    await entityManager.onSubmit({
      transferencia: JSON.stringify({
        ...values,
        idProximoResponsavel: values.novoResponsavel,
      }),
      dataInativacao: new Date(),
      ...openTransferirClientes.data,
    });
    setOpenTransferirClientes((prev) => ({ ...prev, open: false }));
  };

  const enviaEmailConfirmacao = async () => {
    const result = await confirmacaoUsuarioRepository.update({
      id: entityManager.item.id,
    });
    const defaultErrorMessage = 'Erro ao enviar confirmação de e-mail.';
    setSnackbar({
      open: true,
      state: result.ok
        ? {
            message: 'Confirmação de e-mail enviada com sucesso.',
            severity: 'success',
          }
        : {
            message:
              +result.status === 400
                ? result?.data?.message || defaultErrorMessage
                : defaultErrorMessage,
            severity: 'error',
          },
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const fetchTeams = async (name) => {
    const query = {
      name,
      sort: 'name',
    };

    if (!query.name) delete query.name;

    if (officeId) {
      query.officeId = officeId;
    }

    const response = await teamsRepository.list({
      query,
    });

    return response.ok ? response.data : [];
  };

  const fetchOffices = async (name) => {
    const response = await officesRepository.list({
      query: {
        name,
        sort: 'name',
      },
    });

    return response.ok ? response.data : [];
  };

  return (
    <>
      <CrudForm
        {...entityManager}
        disableSubmit={cannotUpdate}
        item={{ ...entityManager.item, formaRecebimento: 'PIX' }}
        onSubmit={handleSubmit}
        validate={validate}
        withPaper
      >
        {({ form, values }) => (
          <Grid container justify="center" spacing={6}>
            <Grid item xs={12}>
              <Section label="Dados Primários">
                <Grid container spacing={2}>
                  <Grid item>
                    <Box className={classes.imageContainer}>
                      <RFFImageBox
                        name="thumbnail"
                        defaultValue={values.thumbnail ?? values.photoUrl}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} lg={4} xl={3}>
                        <Name disabled={cannotUpdate} label="Nome *" />
                      </Grid>
                      <Grid item sm={6} xs={12} lg={4} xl={2}>
                        <TextField
                          endAdornment="validation"
                          disabled={cannotUpdate}
                          name="nickname"
                          label="Apelido"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <CreatedAt disabled />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12} xl={4}>
                        <Email
                          name="username"
                          disabled={cannotUpdate}
                          label="E-mail *"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12} xl={4}>
                        <Select
                          fullWidth
                          disabled={cannotUpdate || cannotUpdateField('roleId')}
                          name="roleId"
                          label="Cargo *"
                          items={roles}
                          hideEmpty
                          onChange={(event) =>
                            cannotShowOperatingSegment(event.target.value)
                          }
                        />
                        <OnChange name="roleId">
                          {(roleId) => {
                            const role = R.find(
                              R.propEq('value', +roleId),
                              roles,
                            );
                            if (
                              !R.isNil(role) &&
                              ![
                                'GERENTE',
                                'GERENTE_PRE_ATENDIMENTO',
                                'CORRETOR',
                                'CORRETOR_1',
                              ].includes(role.key.toUpperCase())
                            ) {
                              form.change('team', null);
                            }
                          }}
                        </OnChange>
                      </Grid>
                      <Grid item md={6} sm={6} xs={12} xl={4}>
                        <Field
                          name="team"
                          label="Time"
                          loadOptions={fetchTeams}
                          getOptionLabel={(option) => option.name ?? ''}
                          parserOption={(option) => option}
                          component={AsyncAutoCompleteAdapter}
                          fullWidth
                          disabled={cannotUpdate || cannotUpdateField('teamId')}
                          onChange={(team) => {
                            const teamOfficeId = team?.officeId;
                            const office = offices.find(
                              (office) => office.id === teamOfficeId,
                            );
                            if (office) {
                              form.change('office', office);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12} xl={2}>
                        <Field
                          name="office"
                          label="Escritório"
                          loadOptions={fetchOffices}
                          getOptionLabel={(option) => option.name ?? ''}
                          parserOption={(option) => option}
                          component={AsyncAutoCompleteAdapter}
                          fullWidth
                          disabled={
                            cannotUpdate || cannotUpdateField('officeId')
                          }
                          onChange={(office) => {
                            setOfficeId(office?.id);
                            form.change('team', null);
                          }}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12} xl={2}>
                        <Select
                          fullWidth
                          disabled={cannotUpdate}
                          name="gender"
                          label="Gênero"
                          items={[
                            { id: 'MALE', value: 'MALE', label: 'Masculino' },
                            {
                              id: 'FEMALE',
                              value: 'FEMALE',
                              label: 'Feminino',
                            },
                            { id: 'OTHER', value: 'OTHER', label: 'Outro' },
                            {
                              id: 'NOT_INFORMED',
                              value: 'NOT_INFORMED',
                              label: 'Prefiro não informar',
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} xl={3}>
                        <DatePicker
                          InputLabelProps={{ className: classes.label }}
                          format="DD/MM/yyyy"
                          fullWidth
                          name="entryDate"
                          label="Data de entrada"
                          disableFuture
                          validate={validateDate}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} xl={3}>
                        <DatePicker
                          InputLabelProps={{ className: classes.label }}
                          format="DD/MM/yyyy"
                          fullWidth
                          name="birthday"
                          label="Data de nascimento"
                          disableFuture
                          validate={validateDate}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12} xl={2}>
                        <Cellphone disabled={cannotUpdate} label="Celular *" />
                      </Grid>
                      <When value={showOperatingSegment === true} is={true}>
                        <Fade in={showOperatingSegment}>
                          <Grid item md={6} sm={12} xs={12} xl={4}>
                            <Select
                              inputLabelProps={{ className: classes.label }}
                              fullWidth
                              disabled={
                                cannotUpdate ||
                                cannotUpdateField('operatingSegment')
                              }
                              name="operatingSegment"
                              label="Segmento de atuação *"
                              items={[
                                {
                                  id: 'LOCACAO',
                                  value: 'LOCACAO',
                                  label: 'Locação',
                                },
                                { id: 'VENDA', value: 'VENDA', label: 'Venda' },
                                { id: 'AMBOS', value: 'AMBOS', label: 'Ambos' },
                              ]}
                            />
                          </Grid>
                        </Fade>
                      </When>
                      {Boolean(chat2DeskIntegration?.config?.active) && (
                        <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                          <Chat2DeskUserField
                            name="chat2DeskId"
                            label="Usuário no Chat2Desk"
                          />
                        </Grid>
                      )}
                      <Grid item xl={2} lg={3} md={3} sm={6} xs={12}>
                        <Field
                          component={SwitchField}
                          disabled={cannotUpdate || cannotUpdateField('leader')}
                          name="leader"
                          label="Líder do time?"
                          type="checkbox"
                        />
                      </Grid>
                      <Grid item lg={2} md={3} sm={6} xs={12}>
                        <Field
                          component={SwitchField}
                          disabled={cannotUpdate || cannotUpdateField('active')}
                          initialValue={true}
                          name="active"
                          label="Ativo?"
                          type="checkbox"
                        />
                      </Grid>
                      <When
                        is={
                          ability.can('update', 'confirmacao-usuario') &&
                          entityManager.item.initExpires != null
                        }
                      >
                        <Grid item xl={8} lg={3} md={4} sm={6} xs={12}>
                          <Typography className={classes.linkReenviarEmail}>
                            <Link
                              href="#"
                              onClick={() => enviaEmailConfirmacao()}
                            >
                              Reenviar link de confirmação de e-mail
                            </Link>
                          </Typography>
                        </Grid>
                      </When>
                    </Grid>
                  </Grid>
                </Grid>
                {validatePasswordField() && (
                  <Grid item className={classes.resetPassword}>
                    <Button
                      color="primary"
                      onClick={() =>
                        history.push('/alterar-senha', {
                          user: entityManager.item,
                        })
                      }
                    >
                      Alterar senha
                    </Button>
                  </Grid>
                )}
              </Section>
            </Grid>
            <Grid item xs={12}>
              <Section label="Social">
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12} xl={3}>
                    <TextField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="facebookUrl"
                      label="Facebook"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12} xl={3}>
                    <TextField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="instagramUrl"
                      label="Instagram"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12} xl={3}>
                    <TextField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="twitterUrl"
                      label="Twitter"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12} xl={3}>
                    <TextField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="blogUrl"
                      label="Blog"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="aboutMe"
                      label="Sobre mim"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Section>
            </Grid>
            <Grid item xs={12}>
              <Section label="Endereço">
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <Address form={form} values={values} />
                  </Grid>
                </Grid>
              </Section>
            </Grid>
            <Grid item xs={12}>
              <Section label="Documentos">
                <Grid container spacing={2}>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="creci"
                      label="CRECI"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Select
                      fullWidth
                      disabled={cannotUpdate}
                      name="creciType"
                      label="Tipo do CRECI"
                      items={[
                        {
                          id: 'NAO_INFORMADO',
                          value: 'NAO_INFORMADO',
                          label: 'Não informado',
                        },
                        {
                          id: 'ESTAGIARIO',
                          value: 'ESTAGIARIO',
                          label: 'Estagiário',
                        },
                        {
                          id: 'CORRETOR',
                          value: 'CORRETOR',
                          label: 'Corretor',
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <DatePicker
                      format="DD/MM/yyyy"
                      fullWidth
                      name="creciExpirationDate"
                      label="Data de expiração"
                      validate={validateDate}
                      disablePast
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="rg"
                      label="RG"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <MaskedField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="cpf"
                      label="CPF"
                      fullWidth
                      mask={CPFMask}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <MaskedField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="cnpj"
                      label="CNPJ"
                      fullWidth
                      mask={CNPJMask}
                    />
                  </Grid>
                </Grid>
              </Section>
            </Grid>
            <Grid item xs={12}>
              <Section label="Recebimento da Comissão de Fiança Digital">
                <Grid container spacing={2}>
                  <Grid item md={4} sm={6} xs={12}>
                    <Select
                      fullWidth
                      disabled={cannotUpdate}
                      name="formaRecebimento"
                      label="Forma de Recebimento"
                      items={[{ value: 'PIX', label: 'Pix' }]}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="codigoPix"
                      label="Chave"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="cpfCnpjPix"
                      label="CPF/CNPJ"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      endAdornment="validation"
                      disabled={cannotUpdate}
                      name="beneficiarioPix"
                      label="Beneficiário"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Section>
            </Grid>
            <Grid item xs={12}>
              <Details
                values={values}
                fields={template}
                detailField="preferencias"
              />
            </Grid>
            <MessageSnackbar
              onClose={handleCloseSnackbar}
              open={snackbar.open}
              severity={snackbar.state.severity}
              message={snackbar.state.message}
              duration={5000}
            />
          </Grid>
        )}
      </CrudForm>
      <When is={confirmDialogProps?.open}>
        <ConfirmDialog
          {...confirmDialogProps}
          submitting={submitting}
          maxWidth="md"
        />
      </When>
      <When is={openTransferirClientes.open}>
        <TransferirEmMassaDialog
          open={openTransferirClientes.open}
          onClose={() =>
            setOpenTransferirClientes((prev) => ({ ...prev, open: false }))
          }
          handleSubmit={handleSubmitTransferencia}
        />
      </When>
    </>
  );
};

const AsyncAutoCompleteAdapter = ({
  input,
  parserOption,
  onChange,
  ...rest
}) => {
  return (
    <AsyncAutoComplete
      {...input}
      onChange={(_event, newValue) => {
        const parsedValue = parserOption(newValue);
        input.onChange(parsedValue);
        onChange && onChange(parsedValue);
      }}
      {...rest}
    />
  );
};

export default UserForm;
